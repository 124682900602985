/* Hamburger Menu*/

.Hamburger {
    cursor: pointer;
    width: 24px;
    height: 24px;
    transition: all 0.25s;
    position: relative;
    background-color: transparent !important;
    outline: none;
}

.HamburgerTop,
.HamburgerMiddle,
.HamburgerBottom {
    position: absolute;
    width: 24px;
    height: 2px;
    top: 0;
    left: 0;
    background: #ffffff;
    transform: rotate(0);
    transition: all 0.5s;
}

.HamburgerMiddle {
    transform: translateY(7px);
}

.HamburgerBottom {
    transform: translateY(14px);
}

.Open {
    transform: rotate(90deg);
}

.Open .HamburgerTop {
    transform: rotate(45deg) translateY(6px) translateX(6px);
}

.Open .HamburgerMiddle {
    display: none;
}

.Open .HamburgerBottom {
    transform: rotate(-45deg) translateY(6px) translateX(-6px);
}
