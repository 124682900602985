.KeywordsDataTable {
    border: none;
}

.KeywordsDataTable td,
.KeywordsDataTable th {
    border: none;
}

.KeywordsDataTable td {
    padding: 20px 20px;
    font-weight: 400;
    font-size: 0.875rem;
}

.KeywordsDataTable tr {
    border-bottom: 2px dashed #f9fafb;
}

@media (max-width: 768px) {
    .KeywordsDataTable tr td:nth-child(2),
    .KeywordsDataTable th:nth-child(2) {
        display: none;
    }

    .KeywordsDataTable tr td:nth-child(2), .KeywordsDataTable tr td:nth-child(3) {
        padding: 20px 2px;
    }

    .KeywordsDataTable th {
        padding: 5px;
    }
}

.scrollable thead,
.scrollable tbody,
.scrollable th,
.scrollable td {
    display: block;
    width: 100%;
}

.scrollable tbody {
    overflow-y: scroll;
}

.scrollable thead {
    -ms-overflow-style: none;
}

.scrollable thead::-webkit-scrollbar {
    visibility: hidden;
}

.scrollable tbody {
    max-height: 80vh;
    position: relative;
    -ms-overflow-style: autohiding-scrollbar;
}

.scrollable tr {
    display: flex;
}

.scrollable tbody th,
.scrollable tbody td {
    border-top: none;
}

.scrollable tbody::-webkit-scrollbar {
    width: 0 !important;
    display: none;
}
