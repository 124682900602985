@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  height: 100vh;
}

@layer base {
  h1 {
    @apply text-4xl mb-12;
  }

  label {
    @apply block text-gray-700 text-sm font-bold mb-2;
  }

  input, textarea {
    @apply border border-gray-300 rounded p-2 mb-3;
  }

  button, button[type="submit"], button[type="button"], button[type="reset"] {
    @apply bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded;
  }

  button.secondary {
    @apply bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded;
  }

  table {
    @apply table-auto border-collapse border border-slate-400;
  }

  th, td {
    @apply border border-slate-300 px-4 py-2;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;  /* for Chrome, Safari and Opera */
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* for Internet Explorer and Edge */
  scrollbar-width: none;  /* for Firefox */
}

